<script>
import Layout from "../../layouts/main.vue";
import PageHeader from "@/components/PageHeader.vue";
import appConfig from "../../../app.config";

import axios from 'axios';
import Container from "@/components/Container.vue";
import Header from "./_Header.vue";
import EpisodeSidebar from "./EpisodeSidebar.vue";
import EpisodeContent from "./EpisodeContent.vue";

export default {
  page: {
    title: "Episode 2",
    meta: [{ name: "description", content: appConfig.description }],
  },
  data() {
    return {
      title: "Episode #",
      data: {
      }
    };
  },
  components: {
    Layout,
    PageHeader,
    Container,
    Header,
    EpisodeSidebar,
    EpisodeContent,
  },
  created: function () {
    this.loadingEpisode();
  },
  mounted: function () {
  },
  methods: {
    loadingEpisode() {
      axios.get('/api/episode/' + this.$route.params.id, { withCredentials: true })
        .then((response) => {
          this.data = response.data;
          window.document.title = response.data.episode.title + ' | ' + window.document.title;
        })
        .catch(function () {
        });
    }
  }
};
</script>

<template>
  <Layout>
    <PageHeader :title="title" v-if="false" />
    
    <Container>
      <template #header>
        <Header v-bind:data="data" v-if="data.episode != null"></Header>
      </template>
      <template #sidebar>
        <EpisodeSidebar v-bind:data="data" v-if="data.episode != null"></EpisodeSidebar>
      </template>
      <template #content>
        <EpisodeContent v-bind:data="data" v-if="data.episode != null"></EpisodeContent>
      </template>
    </Container>
  </Layout>
</template>