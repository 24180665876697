<script>
export default {
  data() {
    return {
    }
  },
  props: {
    data: {
      type: Object,
      default: function () {
        return {
        }
      }
    }
  },
  components: {
  },
  computed: {
    getSecondsOfEpisode() {
      return parseInt(this.data.stat.seconds / 60);
    }
  }
};
</script>

<template>
  <div class="card">
    <div class="card-body">
      <h6 class="text-muted text-uppercase fw-semibold mb-3"> Episode details </h6>
      <p class="text-muted mb-4" v-if="false"></p>
      <div class="table-responsive table-card">
        <table class="table table-borderless mb-0 sidebar-rows">
          <tbody>
            <tr>
              <td class="fw-medium" scope="row">Created</td>
              <td>{{ new Date(data.createdAt).toLocaleString() }}</td>
            </tr>
            <tr v-if="data.publishedAt">
              <td class="fw-medium" scope="row">Published</td>
              <td>{{ new Date(data.publishedAt).toLocaleString() }}</td>
            </tr>
            <tr v-if="data.nSeason > 0">
              <td class="fw-medium" scope="row">Season #</td>
              <td>{{ data.nSeason }}</td>
            </tr>
            <tr v-if="data.nEpisode > 0">
              <td class="fw-medium" scope="row">Episode #</td>
              <td>{{ data.nEpisode }}</td>
            </tr>
            <tr v-if="data.stat.favourites > 0">
              <td class="fw-medium" scope="row">Favs</td>
              <td>{{ data.stat.favourites }}</td>
            </tr>
            <tr v-if="getSecondsOfEpisode > 0">
              <td class="fw-medium" scope="row">Minutes</td>
              <td>{{ getSecondsOfEpisode }}</td>
            </tr>
            <tr v-if="data.stat.posts > 0">
              <td class="fw-medium" scope="row">Posts</td>
              <td>{{ data.stat.posts }}</td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>
</template>
