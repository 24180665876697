<script>
export default {
  data() {
      return {
    }
  },
  props: {
    data: {
      type: Object,
      default: function () {
        return {
        }
      }
    }
  },
  components: {
  },
  methods: {
  },
  mounted() {
  },
  unmounted() {
  },
};
</script>

<template>
  <div :id="'contentGallery_' + data.id" class="row border border-dashed rounded gx-1 p-1" v-if="data.contentGalleries && data.contentGalleries.length > 0">
    <div class="col-3 mb-1" v-for="m in data.contentGalleries" v-bind:key="m">
      <a @click="clickImage" target="_blank">
        <img :src="m.url" alt="" class="img-fluid rounded post-gallery-image">
      </a>
    </div>
  </div>
</template>