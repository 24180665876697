<script>
import { Modal } from 'bootstrap';
import axios from 'axios';
import { write } from 'clipboardy';

export default {
  data() {
    return {
      favClass: 'ri-bookmark-line',
      transcriptionsMode: false,
      favCount: 0,
    }
  },
  props: {
    canedit: {
      type: Boolean,
      default: function () {
        return false;
      }
    },
    data: {
      type: Object,
      default: function () {
        return {
        }
      }
    }
  },
  components: {
  },
  mounted() {
    if (this.data.stat.isFavourite) {
      this.favClass = 'ri-bookmark-fill text-warning';
    }
    else {
      this.favClass = 'ri-bookmark-line';
    }
    this.favCount = this.data.stat.favourites;
  },
  methods: {
    setOrRemoveFavourite(id) {
      const vm = this;
      axios.post('/api/favourites/post/' + id, {}, { withCredentials: true }).then(function (e) {
        if (e.data.toggle) {
          vm.favClass = 'ri-bookmark-fill text-warning';
        } else {
          vm.favClass = 'ri-bookmark-line';
        }
        vm.favCount = e.data.count;
      }).catch(function () {
      });
    },
    copyLink(id) {
      try {
        write('https://busycast.com/post/' + id);
      } catch {
        return;
      }
    },
    openEditor(id){
      var form = document.getElementsByClassName('postEditor')[0];
      var ins = new Modal(form, {});
      form.setAttribute('data-id', id);
      if(ins)
        ins.show();
    },
    deletePost(id) {
      if (confirm('Are you sure?')) {
        var vm = this;
        axios.post('/api/post/delete/' + id, {}, { withCredentials: true }).then(function () {
          vm.$router.go();
        }).catch(function () {
        });
      }
    },
    toggleTranscriptions() {
      this.transcriptionsMode = !this.transcriptionsMode;
    }
  }
};
</script>

<template>
  <div class="hstack gap-2 mt-3">

    <a class="btn btn-sm btn-light" title="Add to favourites" @click="setOrRemoveFavourite(data.id)">
      <span class="me-1"><i :class="favClass"></i></span> {{ favCount }}
    </a>
    <router-link :to="{ name: 'post', params: { id: data.id } }" class="btn btn-sm btn-light" title="Post comments">
      <span class="me-1"><i class="ri-message-3-line"></i></span> {{ data.stat.comments }}
    </router-link>
    <a class="btn btn-sm btn-light" title="Audio to text transcriptions" :class="!data.transcriptionsData ? 'disabled' : ''" v-if="data.transcriptionsData" @click="toggleTranscriptions">
      <span class="me-0"><i class="ri-file-text-line"></i></span>
    </a>

    <div class="dropdown">
      <button class="btn btn-icon btn-sm fs-16 text-muted dropdown" type="button" data-bs-toggle="dropdown" aria-expanded="false"><i class="ri-more-fill"></i></button>
      <ul class="dropdown-menu" style="">
        <li>
          <a class="dropdown-item" href="javascript:void(0);" @click="copyLink(data.id)" title="Copy post link to clipboard">
            <span class="me-1"><i class="ri-link"></i></span> Copy link
          </a>
        </li>
        <li v-if="canedit">
          <a class="dropdown-item" href="javascript:void(0);" @click="openEditor(data.id)" data-id:="" title="Post editor">
            <span class="me-1"><i class="ri-pencil-line"></i></span> Edit
          </a>
        </li>
        <li v-if="canedit">
          <a class="dropdown-item" href="javascript:void(0);" title="Delete your post" @click="deletePost(data.id)">
            <i class="ri-delete-bin-line text-muted me-2 align-bottom"></i>Delete
          </a>
        </li>
      </ul>
    </div>

    <div class="flex-fill text-end">
      <p class="card-text"><small class="text-muted">{{ new Date(data.createdAt).toLocaleString() }}</small></p>
    </div>

  </div>
  <div v-if="data.transcriptionsData && transcriptionsMode" class="mt-2 mb-0 text-small">
    <p class="mb-0">{{ data.transcriptionsValue }}</p>
  </div>
</template>