<script>
import axios from 'axios';
import Recorder from "../recorder/Recorder.vue";

export default {
  data() {
    return {
      buttonCreateDisabled: false,
      buttonCreateText: 'Create',
      title: '',
      titleRecorder: '',
      message: '',
      embed: '',
      previewImage: null,
      addToPodcast: true,
    }
  },
  props: {
    data: {
      type: Object,
      default: function () {
        return {
        }
      }
    }
  },
  components: {
    Recorder,
  },
  methods: {
    modalShown() {
      this.$refs.postRecorder.accessToMedia();
      this.$refs.postRecorder.restoreLevelMeterUpdate();
    },
    modalHidden() {
      this.$refs.postRecorder.stopLevelMeterUpdate();
    },
    preventEnterKey(){
    },
    uploadImage(e){
        const image = e.target.files[0];
        const reader = new FileReader();
        reader.readAsDataURL(image);
        reader.onload = e =>{
            this.previewImage = e.target.result;
        };
    },
    clearUploadImage() {
      this.previewImage = null;
    },
    recorderMode(mode) {
      if(mode === 'on')
        this.buttonCreateDisabled = true;
      else
        this.buttonCreateDisabled = false;
    },
    sourceAudioSelected(label) {
      this.titleRecorder = ' - ' + label;
      console.log('Source audio selected: ' + label);
    },
    sourceVideoSelected(label) {
      console.log('Source video selected: ' + label);
    },
    sendCreatePostForm() {
      //disabled...
      this.buttonCreateDisabled = true;
      this.buttonCreateText = 'Uploading...';

      //blob
      var blob = null;
      var duration = 0;
      if (this.$refs.postRecorder != null && this.$refs.postRecorder.resultBlob != null) {
        blob = this.$refs.postRecorder.resultBlob;
        duration = this.$refs.postRecorder.audioDuration;
      }

      //api
      let formData = new FormData();
      formData.append('type', 1);
      formData.append('title', this.title);
      formData.append('message', this.message);
      formData.append('embed', this.embed);
      formData.append('duration', duration);
      formData.append('record', blob);
      formData.append('image', this.previewImage);
      formData.append('isIgnored', !this.addToPodcast);

      const vm = this;
      axios.post('/api/post/create/' + this.$route.params.id, formData, {
        withCredentials: true,
        headers: {
          'Content-Type': 'multipart/form-data'
        }
      }).then(function () {
        console.log("Post has been created");
        vm.$refs.postRecorder.soundDelete();
        vm.clearFieldsAndUpdate();
      }).catch(function () {
      });
    },
    clearFieldsAndUpdate() {
      this.$router.go();
    },
    recorderInterceptor() {
    },
    readyStreamAccess(stream) {
      console.log('Stream ready', stream);
    }
  }
};
</script>

<template>

<b-modal @shown="modalShown" @hidden="modalHidden" hide-footer :hide-header-close="true" :title="'Recorder' + titleRecorder" class="v-modal-custom zoomIn recorderModal1" size="xl">
  <form>

    <div class="row g-3">
      <div class="col-12">
        <Recorder ref="postRecorder" @recorder-mode="recorderMode" @prerec-click="recorderInterceptor" @stream-ready="readyStreamAccess" @source-audio-selected="sourceAudioSelected" @source-video-selected="sourceVideoSelected" trimmerBackModal=".recorderModal1"></Recorder>
      </div>
      <div class="col-12">
        <div>
          <label for="title" class="form-label">Title</label>
          <input type="text" class="form-control" id="title" placeholder="Your title" v-model="title" v-on:keydown.enter.prevent="preventEnterKey">
        </div>
      </div>
      <div class="col-12 ">
        <div>
          <label for="message" class="form-label">Comment</label>
          <textarea id="message" class="form-control" rows="3" placeholder="Your comment" v-model="message"></textarea>
        </div>
      </div>
      <div class="col-12">
        <div>
          <label for="embed" class="form-label">Embed Url</label>
          <input type="text" class="form-control" id="embed" placeholder="Your embed Url (youtube, vimeo)" v-model="embed" v-on:keydown.enter.prevent="preventEnterKey">
        </div>
      </div>
      <div class="col-12">
        <input type="file" ref="file" accept="image/jpeg" class="d-none" @change=uploadImage>
        <div class="border-dashed rounded p-0 text-center border-1" role="button" @click="$refs.file.click()">
          <button class="btn btn-danger btn-icon waves-effect waves-light position-absolute" @click.stop="clearUploadImage" type="button" style="top:15px;right:25px;">
            <i class="ri-delete-bin-5-line"></i>
          </button>
          <img :src="previewImage" class="w-100 m-0 p-0" />
          <div class="mt-4 mb-5">
            <h5><i class="ri-upload-line"></i> upload image</h5>
          </div>
        </div>
      </div>
      <div class="col-6">
        <div class="form-check form-switch">
          <label for="addToPodcast">Add audio to podcast</label>
          <input class="form-check-input" type="checkbox" role="switch" id="addToPodcast" v-model="addToPodcast">
        </div>
      </div>
    </div>

  </form>

  <div class="modal-footer v-modal-footer">
    <button type="button" @click="sendCreatePostForm" class="btn btn-primary" v-bind:disabled="buttonCreateDisabled">{{ buttonCreateText }}</button>
    <a href="javascript:void(0);" class="btn btn-light fw-medium" data-bs-dismiss="modal">Close</a>
  </div>
</b-modal>
</template>
