<script>
import Author from "../podcast/_Authors.vue";
import Guest from "./_Guests.vue";
import Table from "./_Table.vue";
import EpisodeItem from "../episodes/EpisodeItem.vue";
import PodcastItem from "../podcasts/PodcastItem.vue";
import Actions from "./_Actions.vue";

export default {
  data() {
    return {
    }
  },
  props: {
    data: {
      type: Object,
      default: function () {
        return {
        }
      }
    }
  },
  components: {
    Author,
    Guest,
    Table,
    EpisodeItem,
    PodcastItem,
    Actions,
  },
  computed: {
    isAuth: function() {
      return localStorage.getItem('user.id') !== null;
    }
  }
};
</script>

<template>
  <div class="team-list grid-view-filter row">

    <div class="col-12" v-if="false">
      <EpisodeItem v-bind:data="data.episode" v-bind:podcast="data.podcast"></EpisodeItem>
    </div>

    <div class="col-12" v-if="isAuth">
      <Actions v-bind:data="data.episode"></Actions>
    </div>

    <div class="col-12" v-if="false">
      <PodcastItem v-bind:data="data.podcast"></PodcastItem>
    </div>

    <div class="col-12">
      <Guest v-bind:data="data.episode"></Guest>
    </div>

    <div class="col-12">
      <Author v-bind:data="data.podcast"></Author>
    </div>

    <div class="col-12">
      <Table v-bind:data="data.episode"></Table>
    </div>

  </div>
</template>