<script>
import ContentAbout from "./_About.vue";
import Post from "./_Post.vue";
import Resource from "./_Resource.vue";
import Management from "./_Management.vue";
import Create from "./_Create.vue";
import Last from "./_Last.vue";
import Editor from "../editor/Editor.vue";

export default {
  data() {
    return {
      posts: [],
      resources: []
    }    
  },
  props: {
    data: {
      type: Object,
      default: function () {
        return {
          episodes: [],
        }
      }
    }
  },
  components: {
    ContentAbout,
    Post,
    Create,
    Management,
    Last,
    Resource,
    Editor,
  },
  beforeUpdate() {
    this.posts = [];
    this.resources = [];
  },
  mounted: function () {
  },
  methods: {
    setPost: function(el) {
      if(el)
        this.posts.push(el);
    },
    setResource: function(el) {
      if(el)
        this.resources.push(el);
    },
    stopAllPosts: function(e) {
      this.posts.forEach((key) => {
        key.stopAllMessages(e, false);
      });
      this.resources.forEach((key) => {
        key.stopAllMessages(e, false);
      });
    },
    canEditPost: function(id) {
      return localStorage.getItem('user.id') !== null &&
        (this.data.podcast.permissions.isFounder || localStorage.getItem('user.id') === id)
        &&
        this.data.episode.status === 1;
    }
  },
  computed: {
    canCreatePost: function() {
      return localStorage.getItem('user.id') !== null && 
        (this.data.episode.permissions.isFounder || this.data.episode.permissions.isAuthor || this.data.episode.permissions.isGuest)
        &&
        this.data.episode.status === 1;
    },
    canEditEpisode: function() {
      return localStorage.getItem('user.id') !== null &&
        (this.data.podcast.permissions.isFounder || this.data.podcast.permissions.isAuthor);
    }
  }
};
</script>

<template>

  <ContentAbout v-bind:data="data" v-if="false"></ContentAbout>
  <Management v-if="canEditEpisode && false" v-bind:id="data.episode.id"></Management>

  <div class="acitivity-timeline">
    <div class="acitivity-item d-flex" v-for="p in data.posts" v-bind:key="p">
      <Post :ref="setPost" :key="p.id" v-bind:data="p" :canedit="canEditPost(p.profile.id)" @episode-post-play="stopAllPosts" v-if="p.messages[0].type===1 || p.messages[0].type===3"></Post>
      <Resource :ref="setResource" :key="p.id" v-bind:data="p" @episode-post-play="stopAllPosts" v-if="p.messages[0].type===2 && p.messages[0].resource !== null"></Resource>
    </div>
    <Last v-bind:data="data"></Last>
    <Create v-if="canCreatePost"></Create>
  </div>

  <Editor></Editor>

</template>
