<script>
import Live from "@/views/live/Live.vue";

export default {
  data() {
    return {
      closedModal: false,
    }
  },
  props: {
    data: {
      type: Object,
      default: function () {
        return {
        }
      }
    }
  },
  components: {
    Live
  },
  mounted() {
    this.$refs.pnlLive.isServerSide = true;
  },
  methods: {
    modalShown() {
      this.$refs.pnlLive.accessMedia(true);
      this.$refs.pnlLive.$refs.postRecorder.restoreLevelMeterUpdate();
    },
    modalHidden() {
      this.$refs.pnlLive.$refs.postRecorder.stopLevelMeterUpdate();
    },
  },
  computed: {
  }
};
</script>

<template>
  <b-modal ref="modal" @shown="modalShown" dialog-class="modal-fullscreen-lg-down" :no-close-on-backdrop="!closedModal" :no-close-on-esc="!closedModal" @hidden="modalHidden" hide-footer :hide-header-close="true" :hide-header="true" class="v-modal-custom zoomIn recorderModal2" size="xl">
    <div class="row">
      <div class="col-12">

        <Live ref="pnlLive"></Live>

      </div>
    </div>
  </b-modal>
</template>
