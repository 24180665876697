<script>

export default {
  data() {
    return {
    }
  },
  props: {
    data: {
      type: Object,
      default: function () {
        return {
          guests: []
        }
      }
    }
  },
  components: {
  },
};
</script>

<template>
  <div class="card mb-4" v-if="data.guests.length > 0">
    <div class="card-body">
      <div class="d-flex mb-3">
        <h6 class="card-title mb-0 flex-grow-1">Guests</h6>
        <div class="flex-shrink-0">
        </div>
      </div>

      <ul class="list-unstyled vstack gap-3 mb-0">
        <li v-for="p in data.guests" v-bind:key="p">
          <div class="d-flex align-items-center mt-1">
            <div class="flex-shrink-0">
              <router-link :to="{ name: 'profile', params: { login: p.profile.login } }" class="text-body">
                <div class="avatar-xs flex-shrink-0 me-1" v-if="!p.profile.urlAvatar">
                  <div class="avatar-title rounded-circle">{{ p.profile.login[0].toUpperCase() }}</div>
                </div>
                <img v-bind:src="p.profile.urlAvatar" alt="" class="avatar-xs rounded-circle me-1" v-if="p.profile.urlAvatar">
              </router-link>
            </div>
            <div class="flex-grow-1 ms-2">
              <h6 class="mb-0">
                <router-link :to="{ name: 'profile', params: { login: p.profile.login } }" class="text-body">
                  {{ p.profile.login }}
                </router-link>
              </h6>
              <p class="text-muted mb-0">{{ p.profile.displayName }}</p>
            </div>
            <div class="flex-shrink-0">
              <div class="dropdown">
                <button class="btn btn-icon btn-sm fs-16 text-muted dropdown" type="button" data-bs-toggle="dropdown" aria-expanded="false">
                  <i class="ri-more-fill"></i>
                </button>
                <ul class="dropdown-menu">
                  <li>
                    <router-link :to="{ name: 'profile', params: { login: p.profile.login } }" class="dropdown-item">
                    <i class="ri-eye-fill text-muted me-2 align-bottom"></i> View
                    </router-link>
                  </li>
                </ul>
              </div>
            </div>
          </div>
          <p class="mt-2" v-if="p.title"><strong>{{ p.title }}</strong><br/>{{ p.memo }}</p>
        </li>
      </ul>

    </div>
  </div>
</template>
